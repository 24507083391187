.measure-container {
    flex: 1;
    margin: 24px 24px 32px 24px;
    border-radius: 20px;
    gap: 24px;
}

.measure-container .measure-header {
    padding: 18px 24px 18px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

/* TEXT */

.measure-title-bold {
    font-size: 20px;
    font-weight: 700;
}

.measure-text-bold {
    font-size: 16px;
    font-weight: 500;
    color: #314d51;
}

.measure-text-content {
    font-size: 16px;
    color: #4D797F;
}

/* TABLE */
#measure-table .head {
    display: flex;
    height: 40px;
}

#measure-table .head .h-child {
    flex: 1;
    color: #fff;
    text-align: center;
    padding: 12px 8px;
    font-size: 12px;
    font-weight: 700;
}

#measure-table .green-bg {
    background-color: #314d51;
}

#measure-table .light-green-bg {
    background-color: #4d797f;
}

#measure-table .body .b-row {
    display: flex;
}

#measure-table .body .b-row .b-child {
    flex: 1;
    vertical-align: top;
    padding: 8px;
    text-align: left;
    font-family: "Manrope";
}