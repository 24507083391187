.loader {
    width: 50px;
    height: 50px;
    position: relative;
    transform: translateX(-25px);
}

.ball {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    mix-blend-mode: multiply;
    animation: rotate9 1.5s infinite cubic-bezier(0.77, 0, 0.175, 1);
}

.first {
    background-color: rgb(255, 148, 26);
    animation-delay: 0s;
}

.second {
    background-color: rgb(113, 190, 68);
    animation-delay: 0.5s;
}

.third {
    background-color: rgb(140 44 102);
    animation-delay: 1s;
}

@keyframes rotate9 {

    0%,
    100% {
        left: 50px;
    }

    25% {
        transform: scale(0.3);
    }

    50% {
        left: 0;
    }

    75% {
        transform: scale(1);
    }
}