.rcm-container {
    width: auto;
    height: 1,326px;
    margin: 24px 24px 32px 24px;
    border-radius: 20px;
    gap: 24px;
    background-color: #F2F7F7;
}

.rcm-container .rcm-header {
    background-color: #314d51;
    padding: 18px 24px 18px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}