.request-container {
    width: auto;
    height: 1,326px;
    margin: 24px 24px 32px 24px;
    border-radius: 20px;
    gap: 24px;
    background-color: #F2F7F7;
}

.request-container .request-header {
    background-color: #314d51;
    padding: 18px 24px 18px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.request-container .request-info {
    background-color: #4d797f;
    border-radius: 24px;
    padding: 6px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.request-container .request-info .request-title {
    font-family: 'Manrope', sans-serif;
    font-size: 20px; 
    font-weight: 500;
    color: #f2f7f7;
    margin-bottom: 5px;
}

.request-container .request-info .request-label {
    font-family: 'Manrope', sans-serif;
    font-size: 16px; 
    font-weight: 400;
    color: #d9e6e8;
    margin-bottom: 10px;
}