.prelievi-container {
    width: auto;
    height: 1,326px;
    margin: 24px 24px 32px 24px;
    border-radius: 20px;
    gap: 24px;
    background-color: #F2F7F7;
}

.prelievi-container .prelievi-header {
    background-color: #314d51;
    padding: 18px 24px 18px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.prelievi-container .subtitle {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
}

/* SUCCESS BANNER */
.prelievi-bold-text {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #314d51;
}

.prelievi-text {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #166428;
}

.prelievi-vertical-line {
    width: 4px;
    height: 60px;
    border: 1px solid #4d797f;
    background-color: #4d797f;
    margin-right: 16px;
}

/* FAILURE BANNER */
.prelievi-bold-text-fail {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: #940500;
}

.prelievi-text-fail {
    font-family: 'Manrope';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: #FF443D;
}

.prelievi-vertical-line-fail {
    width: 4px;
    height: 60px;
    border: 1px solid #DB0700;
    background-color: #DB0700;
    margin-right: 16px;
}