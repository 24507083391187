.sc-container {
    width: auto;
    height: 1,326px;
    margin: 24px 24px 32px 24px;
    border-radius: 20px;
    gap: 24px;
    background-color: #F2F7F7;
}

.sc-container .sc-header {
    background-color: #314d51;
    padding: 18px 24px 18px 24px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
}

.sc-container .subtitle {
    font-family: 'Manrope';
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: center;
    text-underline-position: 'from-font';
    text-decoration-skip-ink: none;
}

/* TEXT */
.sc-text-bold {
    font-size: 16px;
    font-weight: 500;
    color: #314d51;
}

.sc-text-content {
    font-size: 16px;
    color: #4D797F;
}