#table-select thead th {
  color: #fff;
  text-align: left;
  padding: 12px 8px;
  font-size: 12px;
}

#table-select tbody td {
  padding: 8px;
  text-align: left;
  font-family: "Manrope";
}

#table-select .checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#table-select .checkbox.selected {
  border-color: #4d797f;
  background-color: #4d797f;
}

#table-select .checkbox.selected::after {
  content: "✓";
  color: #fff;
  font-size: 16px;
}

#table-select .checkbox.selected.orange {
  border-color: #BA6520;
  background-color: #BA6520;
}

#table-select .radio {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#table-select .radio.selected {
  border-color: #4d797f;
}

#table-select .radio.selected::after {
  content: "";
  width: 10px;
  height: 10px;
  background-color: #4d797f;
  border-radius: 50%;
}

#table-select .radio.selected.orange {
  border-color: #BA6520;
}

#table-select .radio.selected.orange::after {
  background-color: #BA6520;
}
  