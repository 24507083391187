@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sectionTitle {
  font-family: 'Manrope';
  font-weight: 700;
  font-size: 24px;
}

.popupSectionTitle {

  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
  color: #182B2E;
}

.summaryTable tr td {
  font-size: 13px;
}

.modalContainer {
  overflow-y: scroll;
  max-height: 80vh;
}

.subItemMenu {
  color: #F5FDFF;
  font-size: 14px;
  font-weight: 400;
  font-family: 'Manrope';
}

.mainItemSubMenu {
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.linkSubMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 10px 0;
}

.linkSubMenuBorder {
  border-bottom: 1px solid #37565A;
}

.subMenu.open {
  animation-name: fade-in;
  animation-duration: .5s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.bigButtonUtils {
  padding: 20px;
  background: #F5FDFF;
  border-radius: 24px;
}

.bigButtonText {
  font-family: 'Manrope';
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
  color: #4D797F;
}

.subMenuAvatar {
  position: absolute;
  right: 0;
}

.headings,
#list-table thead th {
  background-color: #4D797F;
  color: #FFF;
  text-align: left;
  padding: 16px 8px 16px 8px;
  font-size: 12px;
  text-transform: uppercase;
}

#list-table tr:nth-child(odd) {
  background-color: #F2F7F7;
}

#list-table tr {
  background-color: #fff;
  color: '#4D797F';
}

#list-table tbody td {
  padding: 16px 8px 16px 8px;
  text-align: left;
  font-family: 'Manrope';
}

.borderBottomLightGreen {
  border-bottom: 1px solid #D9E6E8;
}

.react-datepicker__header,
.react-datepicker__day--keyboard-selected {
  background-color: #4D797F;
  color: #fff;
}

.react-datepicker__month-dropdown, .react-datepicker__year-dropdown--scrollable {
  background-color: #fff;
  color: #4d797f;
  width: 60%;
  left: 20%;
  border: 1px solid #4d797f;
  font-weight: 600;
}
.react-datepicker__month-option {
  line-height: 22px;
}

.react-datepicker__day-name,
.react-datepicker__time-name,
.react-datepicker__current-month {
  color: #fff;
}

h2.react-datepicker__current-month {
  margin-bottom: 6px;
}

.sunday {
  background-color: #f8d7da;
  color: #940500;
}

.input-container input:disabled {
  background-color: transparent !important;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: #243d40;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  font-size: 13px;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 70%;
  transform: translateX(-70%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Triangolo sotto il tooltip */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 70%;
  transform: translateX(-70%);
  border-width: 5px;
  border-style: solid;
  border-color: #243d40 transparent transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.input-labe {
  font-weight: bold;
}

.separator {
  border-bottom: 1px solid #D9E6E8;
}

.bg-green {
  background-color: #37565A;
}

.bg-orange {
  background-color: #925016;
}

.fieldset-container {
  width: auto;
  height: 1,326px;
  margin: 24px 24px 32px 24px;
  border-radius: 20px;
  gap: 24px;
  background-color: #F2F7F7;
}

.fieldset-container .fieldset-header {
  background-color: #314d51;
  padding: 18px 24px 18px 24px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #d9e6e8;
}

::-webkit-scrollbar-thumb {
  background: #243d40;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: #CBCBEB;
}
