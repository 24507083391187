.infobox-title {
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 700;
    display: block;
    padding-bottom: 8px;
    margin-bottom: 23px;
}

.infobox-box {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-family: 'Manrope', sans-serif;
}

.infobox-content {
    padding: 0px 0 0 2px;
}

.infobox-data {
    display: flex;
    justify-content: space-between;
    gap: 36px;
    align-items: center;
    padding: 3px 0;
}

.content-bold {
    font-size: 16px;
    font-weight: 600;
    padding: 4px 8px;
    border-radius: 4px;
}

.content {
    font-size: 16px;
}

.line {
    margin: 12px 0;
}

.infobox-textarea {
    padding: 12px;
    border-radius: 12px;
}

.infobox-table thead th {
    color: #fff;
    text-align: left;
    padding: 12px 8px;
    font-size: 12px;
}

.infobox-table tbody td {
    padding: 8px;
    text-align: left;
    font-family: "Manrope";
}