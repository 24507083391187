#sc-box {
    border-radius: 10px;
    padding: 12px 24px 24px 24px;
}

#sc-box .row {
    display: flex;
    gap: 24px;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

#sc-box .row .r-child-1 {
    flex: 1;
}

#sc-box .row .r-child-2 {
    flex: 2;
}

#sc-box .row .r-child-3 {
    flex: 3;
}

/* TEXT */
.sc-box-title {
    font-size: 22px;
    font-weight: 500;
}

.sc-box-subtitle {
    font-size: 18px;
    font-weight: 400;
}

.sc-box-content {
    font-size: 16px;
}